<template>
  <div role="group" class="form-group form-row">
    <label v-if="label" :for="name" class="col-form-label" :class="settings.classes.label">{{ $t(label) }}</label>
    <div class="form-inline" :class="settings.classes.input">
      <CInputCheckbox ref="input" :checked="!!text" v-bind="$attrs" v-on="listeners" :custom="custom" :label="$t(placeholder)" />
    </div>
  </div>
</template>

<script>
const ACBCONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'ACheckBox',
  inheritAttrs: true,
  watch: {
    async value(to) {
      this.text = to
      await this.$nextTick()
        //if (this.$refs.input) this.$refs.input.$forceUpdate()
        this.$forceUpdate()
    }
  },
  props: {
    value: {
      type: [String, Number, Boolean, Event]
    },
    label: {
      type: String
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    custom: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => {
          this.$emit('input', event.target.checked)
        },
        change: event => {
          this.$emit('change', event.target.checked)
        }
      }
    }
  },
  data() {
    return {
      settings: { ...ACBCONFIG },
      text: this.value
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
  }
}
</script>

<style lang="scss">
@import '../../../assets/scss/style';

// TODO: check this... forced to avoid -valid
.custom-control-label::before {
  border-color: $primary !important;
}

.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: $primary !important;
}

.custom-control-label {
  color: $input-placeholder-color !important;
  font-size: 0.9em;
  padding-top: 2px;
}
</style>
